<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item label="台账编号:">
          {{ safeLedger.no }}
        </el-form-item>
        <el-form-item v-if="safeLedger.updateDate" label="修改时间:">
          {{ safeLedger.updateDate }}
        </el-form-item>
        <el-form-item v-if="safeLedger.updateUserName" label="最后修改人:">
          {{ safeLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          农药安全管理和安全防护记录
        </caption>
        <tbody>
          <tr>
            <td>检查时间</td>
            <td style="text-align: left">
              <el-date-picker
                v-model="safeLedger.checkDate"
                :disabled="isView"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td>检查人签名</td>
            <td>
              <div v-if="isView">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="getImg(safeLedger.sign)"
                ></el-image>
              </div>
              <div v-else @click="openSign">
                <div v-if="!safeLedger.sign" class="sign">点击签名</div>
                <el-image
                  v-else
                  style="width: 100px; height: 100px"
                  :src="getImg(safeLedger.sign)"
                ></el-image>
              </div>
            </td>
          </tr>
          <tr>
            <td>检查位置</td>
            <td colspan="3" style="text-align: left">
              <el-checkbox-group
                v-model="checkPlace"
                @change="checkPlaceChange"
              >
                <el-checkbox label="门店"></el-checkbox>
                <el-checkbox label="仓库"></el-checkbox>
                <el-checkbox label="门店周边"></el-checkbox>
                <el-checkbox label="仓库周边"></el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="2">检查内容及项目</td>
            <td>检查情况</td>
            <td>处置措施</td>
          </tr>
          <tr v-for="(item, index) in safeItems" :key="index">
            <template v-if="index < 15">
              <td colspan="2" style="text-align: left">{{ item.name }}</td>
              <td>
                <el-checkbox
                  v-if="index < 11"
                  true-label="0"
                  false-label=""
                  :disabled="isView"
                  v-model="item.value"
                  @change="valChange($event, item.name, '0')"
                  >正常</el-checkbox
                >
                <el-checkbox
                  v-else
                  true-label="0"
                  false-label=""
                  :disabled="isView"
                  v-model="item.value"
                  @change="valChange($event, item.name, '0')"
                  >没有</el-checkbox
                >
                <el-checkbox
                  true-label="1"
                  false-label=""
                  :disabled="isView"
                  v-model="item.value"
                  @change="valChange($event, item.name, '1')"
                  >有隐患</el-checkbox
                >
              </td>
              <td>
                <el-checkbox
                  true-label="0"
                  false-label=""
                  :disabled="isView"
                  v-model="item.method"
                  @change="val1Change($event, item.name, '0')"
                  >已处置</el-checkbox
                >
                <el-checkbox
                  true-label="1"
                  false-label=""
                  :disabled="isView"
                  v-model="item.method"
                  @change="val1Change($event, item.name, '1')"
                  >未处置</el-checkbox
                >
              </td>
            </template>
            <template v-else>
              <td colspan="2" style="text-align: left">
                <p>其他需检查事项:</p>
                <el-input
                  style="width: 100%"
                  v-model="item.name"
                  :disabled="isView"
                  type="textarea"
                ></el-input>
              </td>
              <td style="padding-top: 35px">
                <el-input
                  style="width: 100%"
                  v-model="item.value"
                  type="textarea"
                  :disabled="isView"
                ></el-input>
              </td>
              <td style="padding-top: 35px">
                <el-input
                  style="width: 100%"
                  v-model="item.method"
                  type="textarea"
                  :disabled="isView"
                ></el-input>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import {
  getSafeLedgerCode,
  getSafeLedgerDetails,
  addSafeLedger,
  editSafeLedger,
} from "@/api/safeLedger";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      safeLedger: {},
      checkPlace: [],
      isView: false,
      signatureVisible: false,
      safeItems: [
        {
          name: "1、经营场所、仓储场所防盗设施",
          value: "0",
          method: "",
        },
        {
          name: "2、经营场所、仓储场所消防设施",
          value: "0",
          method: "",
        },
        {
          name: "3、经营、仓储场所电线路、照明、电器及上下水管道设施",
          value: "0",
          method: "",
        },
        {
          name: "4、经营场所、仓储场所通风设施",
          value: "0",
          method: "",
        },
        {
          name: "5、经营场所、仓储场所防潮设施",
          value: "0",
          method: "",
        },
        {
          name: "6、经营及搬运人员安全防护措施",
          value: "0",
          method: "",
        },
        {
          name: "7、农药分类陈列、堆放安全措施",
          value: "0",
          method: "",
        },
        {
          name: "8、农药堆（存）放限高安全措施",
          value: "0",
          method: "",
        },
        {
          name: "9、破损（漏）农药安全处理措施",
          value: "0",
          method: "",
        },
        {
          name: "10、农药包装废弃物安全存放设施",
          value: "0",
          method: "",
        },
        {
          name: "11、过期农药暂存安全存放设施",
          value: "0",
          method: "",
        },
        {
          name: "12、经营、仓储场所是否存放有危化品",
          value: "0",
          method: "",
        },
        {
          name: "13、经营、仓储场所是否存放有食品、食用农产品、饮料、酒类、果蔬等",
          value: "0",
          method: "",
        },
        {
          name: "14、经营、仓储场所是否存放有易燃易爆物品（如：烟花爆竹、汽（柴、煤）油、液化气、高压罐等",
          value: "0",
          method: "",
        },
        {
          name: "15、经营、仓储场所是否混放有其他物品（如：生活杂物、日用杂物、其他类农资产品、与农药无关的其他物品",
          value: "0",
          method: "",
        },
        {
          name: "",
          value: "",
          method: "",
        },
      ],
    };
  },
  methods: {
    init(id, view) {
      if (id) {
        getSafeLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.safeLedger = res.data;
            this.checkPlace = res.data.checkPlace.split(";");

            this.safeItems = JSON.parse(res.data.checkText);
            this.isView = view;
          }
        });
      } else {
        getSafeLedgerCode().then((res) => {
          if (res.code == 1) {
            this.safeLedger = {
              ...this.safeLedger,
              no: res.data,
              checkDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getImg(url) {
      return imgUrl + url;
    },
    valChange(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.value = val;
          }
          return v;
        });
      } else {
        if (val === "1") {
          this.safeItems = this.safeItems.map((v) => {
            if (name === v.name) {
              v.method = "0";
            }
            return v;
          });
        }
      }
    },
    val1Change(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.method = val;
          }
          return v;
        });
      }
    },
    back() {
      this.$emit("back");
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.safeLedger.sign) {
        return this.$message.error("请检查人签字");
      }
      if (this.checkPlace.length == 0) {
        return this.$message.error("请选择检查位置");
      }
      this.safeLedger.checkPlace = this.checkPlace.join(";");
      this.safeLedger.checkText = JSON.stringify(this.safeItems);
      if (this.safeLedger.id) {
        editSafeLedger(this.safeLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addSafeLedger(this.safeLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.safeLedger = { ...this.safeLedger, sign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
